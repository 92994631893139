/**=====================
    other css start
==========================**/

// Brand logo CSS start
.logo-block {
    a {
        &:focus {
            outline: none;
        }
    }
    img {
        filter: grayscale(100%);
        opacity: 0.5;
        transition: 0.5s ease;
        margin: 0 auto;
        &:focus {
            outline: none;
        }
        &:hover {
            filter: grayscale(0);
            opacity: 1;
            transition: 0.5s ease;
        }
    }
}

// look-book demo CSS start
.lookbook-section {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    .lookbook-img {
        >div {
            +div {
                img {
                    margin-top: 25px;
                }
            }
        }
    }
}

// lookbook CSS start
.lookbook {
    .lookbook-block {
        position: relative;
        .lookbook-dot {
            cursor: pointer;
            position: absolute;
            z-index: 2;
            width: 29px;
            height: 29px;
            line-height: 29px;
            border-radius: 50%;
            -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            top: 50%;
            left: 12%;
            background-color: $white;
            &.dot2 {
                top: 70%;
                left: 27%;
            }
            &.dot3 {
                top: 28%;
                left: 30%;
            }
            &.dot4 {
                top: 67%;
                left: 8%;
            }
            &.dot5 {
                top: 40%;
                left: 35%;
            }
            &.dot6 {
                top: 80%;
                left: 58%;
            }
            &.dot7 {
                top: 67%;
                left: 45%;
            }
            &.dot8 {
                top: 43%;
                left: 39%;
            }
            &.dot9 {
                top: 32%;
                left: 50%;
            }
            &.dot10 {
                top: 60%;
                left: 50%;
            }
            &.dot11 {
                top: 50%;
                left: 45%;
            }
            &.dot12 {
                top: 75%;
                left: 45%;
            }
            &.dot13 {
                top: 40%;
                left: 45%;
            }
            &.dot14 {
                top: 76%;
                left: 45%;
            }
            &.dot15 {
                top: 50%;
                left: 15%;
            }
            &.dot16 {
                left: 30%;
            }
            &.dot17 {
                top: 78%;
                left: 30%;
            }
            &.dot18 {
                top: 56%;
                left: 62%;
            }
            &.dot19 {
                top: 74%;
                left: 75%;
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                content: "";
                background-color: #fff;
                background-color: rgba(255, 255, 255, 0.5);
                zoom: 1;
                background-color: transparent\9;
                z-index: -1;
                border-radius: 50%;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            span {
                text-align: center;
                justify-content: center;
                display: flex;
            }
            .dot-showbox {
                visibility: hidden;
                top: -98px;
                left: 150%;
                position: absolute;
                width: 130px;
                background-color: $white;
                -webkit-box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
                box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
                img {
                    margin-top: 0;
                }
                .dot-info {
                    padding: 5px;
                    text-align: center;
                    h5 {
                        margin-bottom: 0;
                        text-transform: capitalize;
                        line-height: 20px;
                        &.title {
                            font-weight: 700;
                        }
                    }
                    h6 {
                        text-transform: uppercase;
                        color: var(--theme-deafult);
                        margin-bottom: 0;
                    }
                }
                &:before {
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    border-top: 8px solid transparent;
                    border-right: 8px solid #ffffff;
                    border-bottom: 8px solid transparent;
                    top: 41%;
                    left: -8px;
                }
            }
            &:hover {
                background-color: var(--theme-deafult);
                -webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
                box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
                z-index: 10;
                .dot-showbox {
                    animation: fadeInUp 400ms ease-in-out;
                    visibility: visible;
                    transition: all 0.5s ease;
                }
                span {
                    color: $white;
                }
                &:before {
                    -webkit-transform: scale(1.3);
                }
            }
        }
    }
    &.lookbook-layout {
        .lookbook-block {
            .lookbook-dot {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

// detail
.product-left-title {
    h3 {
        font-weight: 600;
        color: #222;
        text-transform: capitalize;
        max-width: 200px;
        line-height: 36px;
    }
    p {
        line-height: 27px;
        font-size: 18px;
    }
    .slick-custom-arrow {
        margin-top: 16px;
        display: flex;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $round-border;
            border-radius: 100%;
            width: 38px;
            height: 38px;
            cursor: pointer;
            &.left-arrow {
                margin-right: 16px;
            }
        }
        i {
            font-size: 32px;
        }
    }
    &.right-content {
        text-align: right;
        h3 {
            margin-left: auto;
        }
        .slick-custom-arrow {
            justify-content: flex-end;
        }
    }
}

// added cart notification
.added-notification {
    position: fixed;
    top: -60px;
    right: 30px;
    display: flex;
    align-items: center;
    background-color: #3b4156;
    border-radius: 3px;
    padding: 6px;
    overflow: hidden;
    box-shadow: 0 0 2px 1px #e0e0e0;
    transition: all 0.5s ease;
    img {
        width: 25px;
    }
    h3 {
        color: #ffffff;
        text-transform: capitalize;
        font-size: 16px;
        margin-bottom: 0;
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 600;
    }
    &.show {
        top: 30px;
        transition: all 0.5s ease;
    }
}

//alert
.alert {
    .btn-close {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    }
}

//progressbar
.progress-section {
    .progress {
        height: 8px;
        .progress-bar {
            background-color: var(--theme-deafult);
        }
    }
}
.product-box {
    .progress-section {
        margin-top: 10px;
        span {
            margin-top: 6px;
            display: block;
            color: #565656;
        }
    }
}