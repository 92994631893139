/**=====================
    Typography css start
==========================**/

body {
    font-family: $font_0, $font_1;
    position: relative;
    background: $white;
    font-size: 14px;
    &.christmas {
        font-family: $font_2, $font_1;
        .dark-light {
            display: none;
        }
        section {
            overflow: hidden;
        }
    }
}

h1 {
    font-size: 60px;
    color: $dark-font;
    font-weight: 700;
    text-transform: uppercase;
    span {
        font-size: 107px;
        font-weight: 700;
        color: var(--theme-deafult);
    }
}

h2 {
    font-size: 36px;
    color: $dark-font;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
}

h3 {
    font-size: 24px;
    font-weight: 400;
    color: $grey;
    letter-spacing: 0.03em;
}

h4 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1;
}

h5 {
    font-size: 16px;
    font-weight: 400;
    color: $dark-font;
    line-height: 24px;
    letter-spacing: 0.05em;
}

h6 {
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    line-height: 24px;
}

ul {
    padding-left: 0;
    margin-bottom: 0;
}

li {
    display: inline-block;
}

p {
    font-size: 14px;
    color: $grey;
    line-height: 1;
}

a {
    transition: 0.5s ease;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        transition: 0.5s ease;
    }
    &:focus {
        outline: none;
    }
}

button {
    &:focus {
        outline: none;
    }
}

.btn-close {
    &:focus {
        box-shadow: none;
    }
}

label {
    margin-bottom: 0.5rem;
}

:focus {
    outline: none;
}

.form-control {
    &:focus {
        box-shadow: none;
    }
}

.font-cormorant {
    font-family: $font_3, $font-serif;
}

.font-fraunces {
    font-family: 'Fraunces', serif;
}

.font-courgette {
    font-family: 'Courgette', cursive;
    text-transform: capitalize !important; 
}

.text-theme {
    color: var(--theme-deafult) !important;
}

.form-check-input {
    &:checked {
        background-color: var(--theme-deafult);
        border-color: var(--theme-deafult);
    }
}