/**=====================
    service css start
==========================**/

.service-block {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        margin-right: 12px;
    }
    .media {
        padding-top: 23px;
        padding-bottom: 23px;
        align-items: center;
    }
    svg {
        margin-right: 12px;
        width: 60px;
        height: 60px;
        path {
            fill: var(--theme-deafult);
        }
    }
    h4 {
        font-weight: 700;
        text-transform: capitalize;
        letter-spacing: 0.03em;
    }
    p {
        text-transform: capitalize;
        margin-bottom: 0;
    }
    +.service-block {
        border-left: 1px solid lighten($black, 86.5%);
    }
    &:hover {
        svg {
            animation: pulse 1000ms ease-in-out;
            transition: all 0.3s ease;
        }
        h4 {
            color: var(--theme-deafult);
            transition: all 0.3s ease;
        }
    }
}

.service-block1 {
    text-align: center;
    img {
        margin-bottom: 20px;
        width: 60px;
    }
    svg {
        margin-bottom: 20px;
        width: 60px;
        height: 60px;
        path {
            fill: var(--theme-deafult);
        }
    }
    h4 {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    p {
        letter-spacing: 0.03em;
        margin-bottom: -3px;
        line-height: 1.5;
    }
    &:hover {
        svg {
            animation: pulse 1000ms ease-in-out;
            transition: all 0.3s ease;
        }
        h4 {
            color: var(--theme-deafult);
            transition: all 0.3s ease;
        }
    }
}

.service-w-bg {
    .service {
        margin-bottom: -30px;
    }
    .service-block {
        margin-top: 0 !important;
        margin-bottom: 30px;
        p {
            line-height: 1.3;
        }
        + .service-block {
            border: none;
            margin-top: 0 !important;
        }
        .media {
            background-color: whitesmoke;
            width: 100%;
            padding: 20px;
        }
    }
}

.service-style-border {
    .service-block {
        .media {
            padding-top: 0;
            padding-bottom: 0;
        }
        svg {
            width: 80px;
            height: 50px;
        }
        .media-body {
            border-left: 1px solid #dddddd;
            padding-left: 20px;
        }
        + .service-block {
            border-left: none;
        }
    }
}