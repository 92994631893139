/**=====================
    product-box css start
==========================**/

.absolute-product {
    .theme-tab {
        .tab-title {
            .current {
                a {
                    font-weight: 700;
                }
            }
        }
    }
    .product-box {
        width: 100%;
        display: inline-block;
        padding-bottom: 10px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        .img-wrapper {
            border-radius: 5px 5px 0 0;
        }
        .product-detail {
            text-align: center;
            margin-top: 0;
            padding: 0 15px;
            .color-variant {
                padding-top: 5px;
                li {
                    height: 16px;
                    width: 16px;
                }
            }
            .cart-bottom {
                border-top: 1px solid #ddd;
                padding-top: 10px;
                margin-top: 10px;
                padding-bottom: 10px;
                button {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                }
                i {
                    color: #828282;
                    font-size: 18px;
                    padding-right: 7px;
                    padding-left: 7px;
                    transition: all 0.5s ease;
                    &:hover {
                        color: var(--theme-deafult);
                        transition: all 0.5s ease;
                    }
                }
            }
            .rating {
                margin-top: 10px;
            }
        }
    }
    .slick-slider {
        .product-box {
            margin-bottom: 3px;
        }
    }
}

.product-m {
    .slick-list {
        margin-left: -12px;
        margin-right: -12px;
    }
    .slick-slide {
        > div {
            margin: 0 12px;
        }
    }
}

.color-variant {
    li {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        margin-right: 5px;
        transition: all 0.1s ease;
        vertical-align: middle;
    }
}

.quantity-variant {
    li {
        padding: 3px 5px;
        font-size: 12px;
        background-color: #f1f1f1 !important;
        height: auto !important;
        width: auto !important;
        border-radius: 5px !important;
    }
    &.box-l {
        li {
            padding: 6px 8px;
        }
    }
}

.image-swatch-demo {
    li {
        width: 22px !important;
        height: 22px !important;
        overflow: hidden;
    }
}

.image-swatch {
    margin-bottom: 10px;
    li {
        img {
            width: 33px;
            height: 33px;
            padding: 2px;
            object-fit: cover;
            border: 1px solid $round-border;
            margin-right: 5px;
            transition: all 0.5s ease;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            img {
                border: 1px solid var(--theme-deafult);
            }
        }
        &:hover {
            img {
                border: 1px solid var(--theme-deafult);
                transition: all 0.5s ease;
            }
        }
    }
}

.product-box {
    .image-swatch {
        margin-top: 15px;
        li {
            img {
                width: 28px;
                height: 28px;
            }
        }
    }
}

.no-slider {
    .product-box {
        width: 100%;
        flex: 0 0 25%;
        max-width: calc(25% - 30px);
        margin: 0 15px 30px;
        padding: 0;
        &:nth-last-child(-n + 4) {
            margin: 0 15px 0;
        }
    }
    &.five-product {
        .product-box {
            width: 100%;
            flex: 0 0 20%;
            max-width: calc(20% - 30px);
            margin: 0 15px 30px;
            &:nth-last-child(-n + 5) {
                margin: 0 15px 0;
            }
        }
    }
    &.five-product-row {
        .product-box {
            width: 100%;
            flex: 0 0 20%;
            max-width: calc(20% - 30px);
        }
    }
    &.six-product {
        .product-box {
            width: 100%;
            flex: 0 0 20%;
            max-width: calc(16.66% - 30px);
            margin: 0 15px 30px !important;
        }
    }
}

.product-para {
    p {
        margin-bottom: 0;
        padding-bottom: 30px;
        line-height: 24px;
        letter-spacing: 0.05em;
    }
}

.product-box,
.product-wrap {
    position: relative;
    transition: all 0.5s ease;
    vertical-align: middle;
    .img-block {
        background-color: $grey-lighter;
        position: relative;
        overflow: hidden;
        .front {
            opacity: 1;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
            a {
                display: block;
                //height: 500px;
                width: 100%;
            }
        }
        .back {
            opacity: 0;
            position: absolute;
            backface-visibility: hidden;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
            transform: translateX(-100px);
            width: 100%;
            a {
                display: block;
                width: 100%;
            }
        }
        .lable-wrapper {
            margin: 0 auto;
            top: 40px;
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            transition: all 0.5s ease;
            z-index: 2;
            .lable1,
            .lable2 {
                font-size: 14px;
                padding: 10px 14px 10px 20px;
                display: inline-block;
                text-transform: uppercase;
                text-align: center;
            }
            .lable1 {
                background-color: var(--theme-deafult);
                color: $white;
                border-bottom-left-radius: 25px;
                border-top-left-radius: 25px;
            }
            .lable2 {
                background-color: $white;
                color: $black;
                border-bottom-right-radius: 25px;
                border-top-right-radius: 25px;
            }
        }
    }
    .img-wrapper {
        position: relative;
        overflow: hidden;
        z-index: 0;
        .front {
            opacity: 1;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
        }
        .back {
            opacity: 0;
            position: absolute;
            backface-visibility: hidden;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
            transform: translateX(-100px);
            width: 100%;
        }
        .cart-box {
            position: absolute;
            margin: 0 auto;
            display: inline-block;
            right: 0;
            left: 0;
            border-radius: 50px;
            width: max-content;
            padding: 12px 15px;
            box-shadow: 0 0 12px 0 $round-border;
            bottom: 30px;
            background-color: $white;
            opacity: 0;
            transition: all 0.2s ease;
            button {
                background: none;
                box-shadow: none;
                border: none;
                padding: 0;
            }
            i {
                color: $icon;
                font-size: 18px;
                padding-left: 8px;
                padding-right: 8px;
                transition: all 0.2s ease;
                display: inline-block;
                &:hover {
                    color: var(--theme-deafult);
                    transition: all 0.2s ease;
                }
            }
            &.cart-box-bottom {
                bottom: 0;
                border-radius: 0;
                display: flex;
                right: unset;
            }
        }
        .lable-block {
            .lable3 {
                border-radius: 100%;
                background-color: var(--theme-deafult);
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                position: absolute;
                padding: 12px 6px;
                text-transform: uppercase;
                color: $white;
                top: 7px;
                left: 7px;
                z-index: 1;
            }
            .lable4 {
                position: absolute;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                writing-mode: vertical-rl;
                transform: rotate(-180deg);
                top: 7px;
                right: 7px;
                letter-spacing: 0.1em;
                z-index: 1;
                color: #212529;
            }
        }
    }
    .cart-info,
    .cart-wrap {
        position: absolute;
        bottom: 40px;
        text-align: center;
        margin: 0 auto;
        display: inline-block;
        right: 0;
        left: 0;
        justify-content: center;
        opacity: 0;
        transition: all 0.5s ease;
        a {
            [class^="ti-"],
            [class*=" ti-"] {
                display: inline-block;
            }
        }
        &.cart-wrap {
            bottom: 0;
            text-align: right;
            left: unset;
            i {
                display: block;
                padding-bottom: 10px;
                padding-top: 10px;
            }
            &.cart-effect-left {
                left: 0;
                right: unset;
            }
        }
        button {
            background: none;
            box-shadow: none;
            border: none;
            padding: 0;
        }
        i {
            color: $icon;
            font-size: 18px;
            padding-right: 10px;
            padding-left: 10px;
            &:hover {
                color: var(--theme-deafult);
            }
        }
    }
    .cart-detail {
        position: absolute;
        top: 15px;
        right: 20px;
        opacity: 0;
        i {
            color: $icon;
            font-size: 18px;
            display: flex;
            padding-top: 8px;
            padding-bottom: 8px;
        }
        button {
            background: none;
            box-shadow: none;
            border: none;
            padding: 0;
        }
    }
    .product-detail,
    .product-info {
        padding-left: 5px;
        margin-top: 15px;
        .rating {
            i {
                padding-right: 5px;
                &:nth-child(-n + 4) {
                    color: $star-yellow;
                }
                &:last-child {
                    color: $round-border;
                }
            }
        }
        h6 {
            line-height: 1;
            margin-bottom: 0;
            padding-top: 2px;
            padding-bottom: 5px;
            transition: all 0.5s ease;
            font-size: 16px;
            text-transform: capitalize;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        h4 {
            font-size: 18px;
            color: $dark-font;
            font-weight: 700;
            margin-bottom: 0;
            transition: all 0.5s ease;
        }
        .color-variant {
            padding-top: 15px;
            li {
                display: inline-block;
                height: 20px;
                width: 20px;
                border-radius: 100%;
                margin-right: 5px;
                transition: all 0.1s ease;
                cursor: pointer;
            }
        }
    }
    .product-info {
        padding: 0;
        text-align: center;
        position: relative;
        .add-btn {
            position: absolute;
            bottom: 110px;
            margin: 0 auto;
            left: 0;
            right: 0;
            opacity: 0;
            transition: all 0.2s ease;
            z-index: 1;
            i {
                color: var(--theme-deafult);
            }
            .btn-outline {
                transition: all 0.2s ease;
                color: var(--theme-deafult);
                &:hover {
                    color: $white;
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
    &.effect-center {
        .front {
            img {
                transition: all 0.5s ease;
            }
        }
        .img-wrapper {
            .cart-box {
                bottom: 20%;
                transition: all 0.5s ease;
            }
        }
    }
    &:hover {
        &.effect-center {
            .front {
                img {
                    opacity: 0.3;
                    transition: all 0.5s ease;
                }
            }
            .img-wrapper {
                .cart-box {
                    bottom: 35%;
                    transition: all 0.5s ease;
                }
            }
        }
        .img-block,
        .img-wrapper {
            .first {
                opacity: 0;
                transition: all 0.5s ease;
            }
            .back {
                opacity: 1;
                transition: all 0.5s ease;
                transform: translateX(0);
            }
        }
        .cart-info {
            opacity: 1;
            transition: all 0.5s ease;
            button {
                animation: fadeInUp 300ms ease-in-out;
            }
            a {
                &:nth-child(2) i {
                    animation: fadeInUp 500ms ease-in-out;
                }
                &:nth-child(3) i {
                    animation: fadeInUp 700ms ease-in-out;
                }
                &:nth-child(4) i {
                    animation: fadeInUp 1000ms ease-in-out;
                }
            }
        }
        .cart-wrap {
            button {
                animation: fadeInRight 300ms ease-in-out;
            }
            a {
                &:nth-child(2) i {
                    animation: fadeInRight 500ms ease-in-out;
                }
                &:nth-child(3) i {
                    animation: fadeInRight 700ms ease-in-out;
                }
                &:nth-child(4) i {
                    animation: fadeInRight 1000ms ease-in-out;
                }
            }
            &.cart-effect-left {
                button {
                    animation: fadeInLeft 300ms ease-in-out;
                }
                a {
                    &:nth-child(2) i {
                        animation: fadeInLeft 500ms ease-in-out;
                    }
                    &:nth-child(3) i {
                        animation: fadeInLeft 700ms ease-in-out;
                    }
                    &:nth-child(4) i {
                        animation: fadeInLeft 1000ms ease-in-out;
                    }
                }
            }
        }
        .cart-detail {
            opacity: 1;
            transition: all 0.5s ease;
            button {
                animation: fadeInRight 300ms ease-in-out;
            }
            a {
                &:nth-child(2) i {
                    animation: fadeInRight 500ms ease-in-out;
                }
                &:nth-child(3) i {
                    animation: fadeInRight 700ms ease-in-out;
                }
                &:nth-child(4) i {
                    animation: fadeInRight 1000ms ease-in-out;
                }
            }
        }
        .product-info {
            .add-btn {
                opacity: 1;
                transition: all 0.2s ease;
                animation: fadeInUp 500ms ease-in-out;
            }
        }
        .img-wrapper {
            .cart-box {
                opacity: 1;
                transition: all 0.2s ease;
                animation: fadeInUp 400ms ease-in-out;
            }
        }
    }
}

.lable-gradient {
    border-radius: 0;
    background-color: var(--theme-deafult);
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    padding: 4px 12px;
    text-transform: uppercase;
    color: $white;
    top: 7px;
    left: 7px;
    background: linear-gradient(to right, var(--theme-deafult), var(--theme-deafult2));
    z-index: 1;
}

.product-box {
    .cart-info {
        &.bg-color-cls {
            right: -40px;
            button {
                background-color: white;
                animation: none !important;
            }
            a {
                i {
                    background-color: white;
                    animation: none !important;
                }
            }
            &.sm-box {
                i {
                    padding-bottom: 7px;
                    padding-top: 7px;
                    font-size: 16px;
                }
            }
        }
    }
    &:hover {
        .cart-info {
            &.bg-color-cls {
                right: 0;
            }
        }
    }
}

.bg-style {
    i {
        background-color: white;
        padding: 7px !important;
        border-radius: 100%;
        margin: 0 3px;
        font-size: 15px !important;
    }
}

.addtocart_count {
    position: relative;
    .product-box {
        .product-detail {
            margin-top: 8px;
            text-align: center;
        }
        .cart-info {
            bottom: 40px;
            right: 10px;
            a {
                i {
                    background-color: #e2e2e2;
                    border-radius: 100%;
                    margin: 10px 0;
                    padding: 8px;
                    font-size: 16px;
                    color: #313131;
                }
            }
        }
        .add-button {
            background-color: #f5f5f5;
            color: black;
            text-align: center;
            font-size: 18px;
            width: 100%;
            padding: 5px 0;
            transition: all 0.5s ease;
            border: none;
            cursor: pointer;
        }
        &:hover {
            .cart-info {
                a {
                    &:nth-child(1) {
                        i {
                            animation: fadeInRight 300ms ease-in-out;
                        }
                    }
                }
            }
            .add-button {
                bottom: 0;
                transition: all 0.5s ease;
            }
        }
    }
    .addtocart_btn {
        position: relative;
        .cart_qty {
            width: 100%;
            &.qty-box {
                position: absolute;
                bottom: 0;
                display: none;
                .input-group {
                    .form-control {
                        width: 100%;
                        border-color: #efefef;
                        &:focus {
                            border-color: #efefef;
                            box-shadow: none;
                        }
                    }
                    button {
                        background: $grey3 !important;
                        position: absolute;
                        height: 100%;
                        z-index: 9;
                        &.quantity-left-minus {
                            left: 0;
                        }
                        &.quantity-right-plus {
                            right: 0;
                        }
                    }
                    button {
                        i {
                            color: $black;
                        }
                    }
                }
            }
            &.open {
                display: block;
            }
        }
    }
}

.grid-products {
    margin-bottom: -30px;
    .product-box {
        margin-bottom: 30px;
    }
}

.bg-title {
    .theme-card {
        h5 {
            &.title-border {
                padding: 10px;
                color: white;
                background-color: var(--theme-deafult);
                border-radius: 5px;
            }
        }
        .slick-prev {
            right: 30px;
            &:before {
                color: white;
                opacity: 1;
                font-size: 25px;
            }
        }
        .slick-next {
            right: 6px;
            &:before {
                color: white;
                opacity: 1;
                font-size: 25px;
            }
        }
    }
    .theme-tab {
        .bg-title-part {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            margin-top: -6px;
            background-color: var(--theme-deafult);
            padding: 10px;
            border-radius: 5px;
            .title-border {
                margin-bottom: 0;
                color: white;
                text-transform: capitalize;
            }
            .tab-title {
                margin-bottom: 0;
                margin-top: 0;
                margin-left: auto;
                text-align: right;
                li {
                    font-size: 16px;
                    padding-right: 0;
                    &:first-child {
                        padding-left: 0;
                    }
                }
                a {
                    color: rgba(white, 0.7);
                }
                .current {
                    a {
                        color: white;
                    }
                }
                &.w-bg {
                    li {
                        padding: 4px 18px !important;
                        &.current {
                               background-color: var(--theme-deafult);
                            a {
                                color: white;
                            }
                        }
                    }
                }
            }
            &.wo-bg {
                background-color: #f8f9fa;
                .tab-title {
                    a {
                        color: #2d2a25;
                    }
                    .current {
                        a {
                            color: var(--theme-deafult);
                        }
                    }
                }
                .title-border {
                    font-size: 18px;
                    font-weight: 600;
                    color: #222222;
                    &.small-title {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    &.wo-bg {
        .theme-tab {
            .bg-title-part {
                background-color: transparent;
                padding: 0 0 14px 0;
                border-radius: 0;
                border-bottom: 1px solid #ddd;
                .title-border {
                    color: #000000;
                    font-size: 20px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
            .tab-title {
                a {
                    color: #2d2a25;
                }
                .current {
                    a {
                        color: var(--theme-deafult);
                    }
                }
            }
        }
    }
}

.product-christmas {
    .product-box {
        position: relative;
        transition: all 0.5s ease;
        .cart-info,
        .cart-wrap {
            position: absolute;
            bottom: -26px;
            text-align: center;
            margin: 0 auto;
            right: 0;
            left: 0;
            justify-content: center;
            opacity: 0;
            transition: all 0.5s ease;
            display: flex;
            a {
                [class^="icon-"],
                [class*=" icon-"] {
                    display: inline-block;
                }
            }
            button {
                background: none;
                box-shadow: none;
                border: none;
                padding: 0;
                color: $white;
            }
            i {
                color: $white;
                font-size: 18px;
                padding-right: 10px;
                padding-left: 10px;
            }
        }
        .cart-wrap {
            bottom: -26px;
            text-align: right;
            left: 0;
            i {
                display: block;
                padding-bottom: 10px;
                padding-top: 10px;
            }
            &.cart-effect-left {
                left: 0;
                right: unset;
            }
        }
        .product-detail,
        .product-info {
            padding-left: 0;
            text-align: center;
            .rating {
                i {
                    &:nth-child(-n + 4) {
                        color: var(--theme-deafult);
                    }
                    &:last-child {
                        color: #878787;
                    }
                }
            }
            h6 {
                line-height: 1;
                margin-bottom: 3px;
                padding-top: 7px;
                padding-bottom: 5px;
                transition: all 0.5s ease;
                font-size: 16px;
                color: $grey4;
                font-weight: 700;
            }
            h4 {
                font-size: 18px;
                color: var(--theme-deafult);
                font-weight: 700;
                margin-bottom: 0;
                transition: all 0.5s ease;
            }
        }
        &:hover {
            .cart-info {
                bottom: 0;
                background-color: var(--theme-deafult);
                opacity: 1;
                transition: all 0.5s ease;
                button {
                    animation: none !important;
                }
                a {
                    i {
                        animation: none !important;
                    }
                }
            }
            .product-info {
                .add-btn {
                    opacity: 1;
                    transition: all 0.2s ease;
                    animation: fadeInUp 500ms ease-in-out;
                }
            }
            .img-wrapper {
                .cart-box {
                    opacity: 1;
                    transition: all 0.2s ease;
                    animation: fadeInUp 400ms ease-in-out;
                }
            }
        }
    }
}

.game-product {
    .theme-tab {
        .tab-title,
        .tab-title2 {
            .current {
                a {
                    border-bottom: 2px solid var(--theme-deafult);
                    padding-bottom: 5px;
                }
            }
        }
    }
    .product-box {
        .product-detail {
            text-align: center;
        }
        .cart-info {
            bottom: 40px;
            right: 10px;
            a {
                i {
                    background-color: white;
                    border-radius: 100%;
                    margin: 10px 0;
                    padding: 8px;
                    font-size: 16px;
                }
            }
        }
        .add-button {
            background-color: var(--theme-deafult);
            color: white;
            text-align: center;
            font-size: 18px;
            text-transform: capitalize;
            position: absolute;
            width: 100%;
            bottom: -40px;
            padding: 5px 0;
            transition: all 0.5s ease;
            cursor: pointer;
        }
        &:hover {
            .cart-info {
                a {
                    &:nth-child(1) {
                        i {
                            animation: fadeInRight 300ms ease-in-out;
                        }
                    }
                }
            }
            .add-button {
                bottom: 0;
                transition: all 0.5s ease;
            }
        }
    }
    .img-wrapper {
        img {
            width: 100%;
        }
    }
}

.tools-grey {
    .product-box {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        background-color: white;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
        .img-wrapper {
            .lable-block {
                transition: all 0.5s ease;
            }
        }
        .cart-info {
            display: flex;
            left: 0;
            bottom: -30px;
            transition: all 0.5s ease;
            .mobile-quick-view {
                display: none;
            }
            a {
                background-color: #eef0f1;
                border-radius: 5px;
                i {
                    color: $grey2;
                }
                &:hover {
                    background-color: var(--theme-deafult);
                    transition: all 0.5s ease;
                    i {
                        color: $white;
                        transition: all 0.5s ease;
                    }
                }
            }
            button {
                width: 55%;
                background-color: #eef0f1;
                color: $grey2;
                border-radius: 5px;
                margin: 0 5px;
                transition: all 0.5s ease;
                i {
                    display: none;
                }
                &:hover {
                    background-color: var(--theme-deafult);
                    color: $white;
                    transition: all 0.5s ease;
                }
            }
        }
        .quick-view-part {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -20px;
            background-color: var(--theme-deafult);
            border-radius: 100%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: all 0.5s ease;
            i {
                font-size: 18px;
                color: $white;
            }
        }
        .product-info {
            padding: 15px;
            background-color: $white;
            border-top: 1px solid rgba($round-border, 0.2);
            padding-top: 0;
            margin-top: 0;
            .rating {
                margin-top: 0;
                padding-top: 0;
            }
        }
        &:hover {
            .img-wrapper {
                .front {
                    img,
                    .bg-size {
                        opacity: 0.05;
                        transition: all 0.5s ease;
                    }
                }
                .cart-info {
                    opacity: 1;
                    transition: all 0.5s ease;
                    bottom: 10px;
                    button {
                        animation: none;
                    }
                    a {
                        &:nth-child(2) i {
                            animation: none;
                        }
                        &:nth-child(3) i {
                            animation: none;
                        }
                        &:nth-child(4) i {
                            animation: none;
                        }
                    }
                }
                .lable-block {
                    display: none;
                    transition: all 0.5s ease;
                }
            }
            .quick-view-part {
                opacity: 1;
                transition: all 0.5s ease;
            }
            .ribbon {
                display: none;
                transition: all 0.5s ease;
            }
        }
    }
    .ribbon {
        position: absolute;
        left: -5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;
        width: 75px;
        height: 75px;
        text-align: right;
        transition: all 0.5s ease;
        span {
            font-size: 10px;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            line-height: 20px;
            transform: rotate(-45deg);
            width: 100px;
            display: block;
            background: var(--theme-deafult);
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 19px;
            left: -21px;
            &:before {
                content: "";
                position: absolute;
                left: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid var(--theme-deafult);
                border-right: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-top: 3px solid var(--theme-deafult);
            }
            &:after {
                content: "";
                position: absolute;
                right: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid transparent;
                border-right: 3px solid var(--theme-deafult);
                border-bottom: 3px solid transparent;
                border-top: 3px solid var(--theme-deafult);
            }
        }
    }
}

.j-box {
    .product-box {
        width: 100%;
        display: inline-block;
        border: 1px solid #ddd;
        border-radius: 12px;
        overflow: hidden;
        .cart-info {
            display: flex;
            left: 0;
            background-color: rgba(250, 250, 250, 0.84);
            border-top: 1px solid $round-border;
            border-bottom: 1px solid $round-border;
            bottom: -30px;
            transition: all 0.5s ease;
            a {
                border-left: 1px solid $round-border;
                i {
                    padding: 12px;
                }
            }
        }
        .product-detail {
            padding-left: 15px;
            padding-bottom: 15px;
            background-color: #fafafa;
            padding-top: 15px;
            margin-top: 0;
            .rating {
                margin-top: 0;
            }
        }
        &:hover {
            .img-wrapper {
                .cart-info {
                    opacity: 1;
                    transition: all 0.5s ease;
                    bottom: 0;
                    button {
                        animation: none;
                    }
                    a {
                        &:nth-child(2) i {
                            animation: none;
                        }
                        &:nth-child(3) i {
                            animation: none;
                        }
                        &:nth-child(4) i {
                            animation: none;
                        }
                    }
                }
            }
        }
    }
    &.style-box {
        .product-box {
            padding: 10px;
            .product-detail {
                background-color: white;
            }
            .cart-info {
                background-color: transparent;
                border: none;
                a {
                    border: none;
                }
            }
        }
    }
}

.bakery-box {
    .product-box {
        .img-wrapper {
            border: 5px solid white;
            box-shadow: 0 0 8px rgba(black, 0.12);
            .lable-block {
                .lable3 {
                    background-color: #ffffff;
                    color: var(--theme-deafult);
                    padding: 4px 22px;
                    border-radius: 0;
                }
            }
        }
        .product-detail {
            text-align: center;
            padding-left: 0;
            h6 {
                padding-top: 7px;
                padding-bottom: 12px;
            }
        }
        .cart-info {
            top: 0;
            right: 10px;
            a {
                i {
                    background-color: white;
                    border-radius: 100%;
                    margin: 10px 0;
                    padding: 8px;
                    font-size: 16px;
                }
            }
        }
        .add-button {
            background-color: var(--theme-deafult);
            color: white;
            text-align: center;
            font-size: 18px;
            text-transform: capitalize;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            bottom: -40px;
            padding: 5px 0;
            transition: all 0.5s ease;
            cursor: pointer;
        }
        &:hover {
            .cart-info {
                a {
                    &:nth-child(1) {
                        i {
                            animation: fadeInRight 300ms ease-in-out;
                        }
                    }
                }
            }
            .add-button {
                bottom: 20px;
                transition: all 0.5s ease;
            }
        }
    }
    .img-wrapper {
        img {
            width: 100%;
        }
    }
}

.product-box {
    &.single-product {
        text-align: center;
        .product-detail {
            h6 {
                font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320)));
            }
            h4 {
                font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
            }
            .btn-solid {
                padding: 12px 56px;
                margin-top: 20px;
            }
        }
        .color-variant {
            display: flex;
            justify-content: center;
            border-bottom: none;
            .nav-item {
                width: 30px !important;
                height: 30px !important;
                margin: 0 10px;
                &.color1 {
                    background-color: #19627e;
                }
                &.color2 {
                    background-color: #e7e3e2;
                }
                &.color3 {
                    background-color: #c560a4;
                }
            }
            .nav-link {
                padding: 0;
                background-color: transparent;
                border: none;
                width: 30px !important;
                height: 30px !important;
                &.active {
                    padding: 0;
                    background-color: transparent;
                    border: none;
                }
            }
        }
    }
}

.product-style-1 {
    &.product-box {
        border: 1px solid #f5f5f5;
        padding: 10px;
        .product-detail {
            h6 {
                padding-bottom: 10px;
            }
        }
    }
}

.product-style-2 {
    &.product-box , .product-wrap {
        .product-info {
            .color-variant {
                transition: all 0.5s ease;
            }
            .add-btn {
                bottom: 0;
                a {
                    color: var(--theme-deafult);
                    text-transform: capitalize;
                    font-size: calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320)));                
                }
            }
        }
        &:hover {
            .product-info {
                .color-variant {
                    opacity: 0;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}


.product-style-3 {
    &.product-box , .product-wrap {
        .product-info {
            margin-top: 8px;
            .add-btn {
                bottom: 0;
                position: relative;
                opacity: 1;
                margin-top: 8px;
                animation: none;
                a {
                    color: var(--theme-deafult);
                    text-transform: capitalize;
                    font-size: calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320)));                
                }
            }
        }
    }
}

.addtocart_count {
    .product-box {
        &.product-style-1 {
            .add-button {
                background-color: #f8f8f8;
                margin-top: 10px;
            }
        }
    }
}

.dark-box {
    margin-right: -5px;
    margin-left: -5px;
    > div {
        flex: 0 0 20% !important;
        max-width: 20% !important;
        padding: 0 5px;
    }
    .product-box {
        border: 1px solid transparent;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: -52px;
        .img-wrapper {
            overflow: unset;
        }
        .cart-detail {
            i {
                padding: 8px;
                border-radius: 100%;
                background-color: var(--theme-deafult);
                font-size: 14px;
                color: white;
                margin-bottom: 10px;
            }
        }
        .product-info {
            padding-bottom: 52px;
            h6 {
                padding-top: 5px;
                padding-bottom: 10px;
            }
            .add-btn {
                position: relative;
                bottom: 0;
                margin-top: 14px;
                opacity: 1;
                display: none;
                animation: none;
                .btn {
                    padding: 7px 33px;
                }
            }
        }
        &:hover {
            border: 1px solid #404040;
            z-index: 1;
            background-color: #2b2b2b;
            .product-info {
                padding-bottom: 0;
                .add-btn {
                    display: block;
                }
            }
        }
    }
    &.four-product {
        margin-bottom: -20px;
        > div {
            max-width: 25% !important;
            flex: 0 0 25% !important;
            margin-bottom: 20px;
        }
    }
}

.product-box {
    .product-arrow {
        .slick-prev,
        .slick-next {
            opacity: 0;
            transform: scale(0);
            transition: all 0.5s ease;
            margin-top: -10px;
            &:before {
                font: normal normal normal 14px/1 $font-awesome;
                opacity: 1;
                color: black;
                background-color: rgba($white, 0.5);
                border-radius: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .slick-next {
            right: 1px;
            &:before {
                content: "\f105";
            }
        }
        .slick-prev {
            left: 1px;
            z-index: 1;
            &:before {
                content: "\f104";
            }
        }
        &:hover {
            .slick-prev,
            .slick-next {
                opacity: 1;
                transform: scale(1.2);
                transition: all 0.5s ease;
                &:before {
                    opacity: 1;
                }
            }
            .slick-next {
                right: 30px;
            }
            .slick-prev {
                left: 30px;
            }
        }
    }
}

.detail-inline {
    display: flex;
    h4 {
        margin-left: auto;
    }
}

.infinite-product {
    .product-box {
        display: none;
    }
}

.load-more-button {
    .btn {
        transition:
        width 0.1s ease,
        height 0.4s ease,
        border-width 0.4s ease,
        border-radius 0.4s ease,
        border-color 0.4s ease,
        padding 0.4s ease;
        &:focus {
            outline: none;
          }
          &.loading {
            height: 24px;
            width: 24px;
            padding: 0;
            border-width: 2px;
            border-radius: 17px;
            border-color:
              #eee #eee #eee #999;
            background: #fff;
            font-size: 0;
            color: transparent;
            pointer-events: none;
            animation: spin 1.2s infinite linear;
            &:before {
                opacity: 0;
            }
          }
    }
}


@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }