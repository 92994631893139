/**=====================
     Reset css start
==========================**/
section,
.section-t-space {
    padding-top: 70px;
}

.section-b-space {
    padding-bottom: 70px;
}

.large-section {
    padding-top: 120px;
    padding-bottom: 120px;
}

hr.style1 {
    width: 75px;
    height: 3px;
    margin-top: 13px;
    background-color: var(--theme-deafult);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
}

.table {
    thead {
        th {
            border-bottom: 1px solid #dee2e6 !important;
        }
    }
}

.form-control {
    border-radius: 0;
}

.small-section {
    padding-top: 35px;
    padding-bottom: 35px;
}

.banner-padding {
    padding-top: 30px;
}

.border-section {
    border-top: 1px solid lighten($black, 86.5%);
    border-bottom: 1px solid lighten($black, 86.5%);
}

.border-b {
    border-bottom: 1px solid $border-color;
}

.border-bottom-grey {
    border-bottom: 1px solid $grey3;
}

.border-top-grey {
    border-top: 1px solid $grey3;
}

.darken-layout {
    background-color: $grey-darken;
}

.dark-layout {
    background-color: $grey-dark;
}

.light-layout {
    background-color: $grey-lighter;
}

.white-layout {
    background-color: $white;
}

.bg-light0 {
    background-color: $light-grey;
}

.bg-light1 {
    background-color: $pink;
}

.bg-light2 {
    background-color: $blue;
}

.bg-color1 {
    background-color: #c6c6c6;
}

.bg-color2 {
    background-color: #ab5252;
}

.bg-color3 {
    background-color: #6d6d6d;
}

.bg-blog {
    background-color: $white-dark;
}

.bg-grey {
    background-color: $grey-about;
}

.bg_cls {
    background-color: #fafafa;
}

.bg-loader {
    background-color: $bg-loader;
}

.badge-theme-color {
    background-color: var(--theme-deafult);
    color: white;
}

.badge-grey-color {
    background-color: #929292;
    color: white;
}

.overflow-hidden {
    overflow: hidden;
}

del {
    font-size: 14px;
    color: $font-grey;
    font-weight: 400;
}

.position-unset {
    position: unset !important;
}

[data-notify="progressbar"] {
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
}

.progress-bar {
    background-color: #19a340;
}

.progress-bar-info {
    background-color: #00829a;
}

.container-fluid {
    &.custom-container {
        padding-left: 90px;
        padding-right: 90px;
    }
}

.left-sidebar_space {
    padding-left: 300px;
}

.space_sm {
    padding-left: 240px;
    padding-top: 74px !important;
}

.left-sidebar_space-xl {
    padding-left: 380px;
}

.background-transparent {
    background-color: transparent !important;
}

.bg-overlay {
    background-blend-mode: overlay;
    background-color: rgba(#fafafa, 0.98);
}

.bg-blue-light {
    background-color: rgba(#2873ef, 0.06);
}

.bg-theme {
    background-color: var(--theme-deafult);
    background-color: var(--theme-deafult2);
}

/*Lazy load */

.blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
    &.lazyloaded {
        -webkit-filter: blur(0);
        filter: blur(0);
    }
}

.margin-default {
    margin-bottom: -30px;
    > div {
        margin-bottom: 30px;
    }
    &.no-slider {
        .product-box {
            margin-bottom: 30px !important;
        }
    }
}

.pl-section {
    padding-left: 310px;
}

.section-white {
    section {
        background-color: white;
    }
}

.w-80 {
    width: 80% !important;
}

.fz-16 {
    font-size: 16px;
}

.form-group {
    margin-bottom: 15px;
}

.form-check-input {
    &:focus {
        box-shadow: none;
    }
}

.media {
    display: flex;
    align-items: flex-start;
    .media-body {
        flex: 1;
    }
}

.form-check {
    display: flex;
}

.input-group-prepend,
.input-group-append {
    display: flex;
}

.breadcrumb {
    padding: 0.75rem 1rem;
}

.row {
    div[class*="col-"],
    .col {
        position: relative;
    }
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.sticky-top-section {
    position: sticky;
    top: 150px;
    z-index: 1;
}
