/*=====================
 Title start
==========================*/

.title1 {
    text-align: center;
    h4 {
        color: var(--theme-deafult);
        padding-bottom: 10px;
        margin-bottom: 0;
        margin-top: -3px;
    }
    .title-inner1 {
        padding-bottom: 15px;
        margin-bottom: 30px;
        position: relative;
        &:after {
            position: absolute;
            height: 5px;
            width: 70px;
            background-color: var(--theme-deafult);
            content: "";
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 0;
        }
    }
    &.title-gradient {
        .title-inner1 {
            &:after {
                background: var(--theme-deafult);
                background: linear-gradient(180deg, var(--theme-deafult) 0%, var(--theme-deafult2) 99%);
                background-color: var(--theme-deafult);
            }
        }
    }
    &.title5 {
        .title-inner1 {
            padding-bottom: 0;
            margin-bottom: 0;
            &:after {
                display: none;
            }
        }
        hr[role="tournament6"] {
            border: 0px solid;
            height: 1px;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0), var(--theme-deafult), rgba(0, 0, 0, 0));
            display: block;
            width: 25%;
            margin: 15px auto 30px auto;
        }
        hr[role="tournament6"]::before {
            position: absolute;
            background-color: #efefef;
            border: 1px solid;
            border-color: var(--theme-deafult);
            padding: 5px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            left: 50%;
            margin: -6px 0px 0px 0;
            content: "";
        }
    }
    &.wo-border {
        .title-inner1 {
            padding-bottom: 0;
            &:after {
                display: none;
            }
        }   
    }
}

.title2 {
    text-align: center;
    position: relative;
    z-index: 1;
    h4 {
        color: var(--theme-deafult);
        padding-bottom: 10px;
        margin-bottom: 0;
        text-transform: uppercase;
        margin-top: -3px;
    }
    .title-inner2 {
        margin-bottom: 50px;
        position: relative;
        display: inline-block;
        padding: 0 25px;
        &:after {
            position: absolute;
            height: 5px;
            width: 100%;
            background-color: var(--theme-deafult);
            opacity: 0.4;
            content: "";
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 3px;
            z-index: -1;
        }
    }
}

.title3 {
    text-align: center;
    h4 {
        color: var(--theme-deafult);
        padding-bottom: 10px;
        margin-bottom: 5px;
        margin-top: -3px;
    }
    .title-inner3 {
        margin-bottom: 20px;
        position: relative;
        margin-top: -5px;
    }
    .line {
        position: relative;
        height: 5px;
        width: 45px;
        background-color: var(--theme-deafult);
        content: "";
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        margin-bottom: 50px;
        &:after,
        &:before {
            position: absolute;
            height: 5px;
            width: 10px;
            content: "";
            background-color: var(--theme-deafult);
        }
        &:after {
            right: 50px;
        }
        &:before {
            left: 50px;
        }
    }
}

.title4 {
    text-align: center;
    .title-inner4 {
        padding-bottom: 25px;
        margin-bottom: 0;
        position: relative;
        margin-top: -5px;
    }
    .line {
        position: relative;
        height: 1px;
        width: 180px;
        background-color: var(--theme-deafult);
        margin: 0 auto;
        margin-bottom: 55px;
        bottom: 0;
        top: 0;
        &:after,
        &:before {
            position: absolute;
            height: 10px;
            width: 10px;
            content: "";
            background-color: var(--theme-deafult);
            top: -4px;
        }
        &:after {
            left: 78px;
        }
        &:before {
            left: 64px;
        }
        span {
            &:after,
            &:before {
                position: absolute;
                height: 10px;
                width: 10px;
                content: "";
                background-color: var(--theme-deafult);
                top: -4px;
            }
            &:after {
                right: 78px;
            }
            &:before {
                right: 64px;
            }
        }
    }
}

.title6 {
    text-align: center;
    h2 {
        margin-top: -5px;
        margin-bottom: 20px;
    }
}

.title7 {
    margin-bottom: 20px;
    .title {
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 0;
    }
    .sub-title {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 0;
        color: #7b7b7b;
        margin-top: 2px;
        text-transform: capitalize;
    }
}

.title-borderless {
    margin-bottom: 30px;
    text-align: center;
    margin-top: -5px;
}

.about-text {
    p {
        line-height: 28px;
        letter-spacing: 0.06em;
        text-align: center;
        margin-bottom: 50px;
    }
}

.theme-tab {
    .title-inline {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        border-bottom: 1px solid #dddddd;
        h3 {
            text-transform: capitalize;
            margin-bottom: 0;
            color: black;
            font-weight: 600;
        }
        .tab-title {
            margin-bottom: 0;
            margin-left: auto;
            margin-top: 0;
            li {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 10px;
                margin: 0 15px;
            }
            .current {
                border-bottom: 2px solid var(--theme-deafult);
            }
        }
    }
}

.title-basic {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    .title {
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 0;
    }
    .timer {
        margin-top: 0;
        margin-left: 15px;
        background-color: #f5f5f5;
        p {
            color: #000000;
        }
        span {
            .timer-cal {
                display: none;
            }
        }
    }
}

.title-gradient {
    // background: linear-gradient(-45deg, var(--theme-deafult), var(--theme-deafult2), #23a6d5, #23d5ab);
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 400%;
    -webkit-animation: gradient 15s ease infinite;
    animation: gradient 15s ease infinite;
}


