/**=====================
    Theme setting css start
==========================**/

.color-picker {
    position: fixed;
    right: -190px;
    top: calc(38vh + 220px);
    width: 190px;
    z-index: 1;
    transition: all 0.3s ease;
    .settings-header {
        background: #f3f3f3;
        padding: 5px 15px;
        box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62);
        h3 {
            color: black;
            margin: 0;
            font-size: 20px;
            padding: 4px 9px;
        }
    }
    .section {
        background: white;
        padding: 10px;
        border: 1px solid #f3f3f3;
        overflow: auto;
        i {
            font-size: 16px;
            margin-right: 10px;
        }
    }
    a {
        &.handle {
            position: absolute;
            right: 190px;
            width: 45px;
            height: 41px;
            transition: all ease-in 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            z-index: -1;
            text-decoration: none;
            background-color: rgb(249, 249, 249);
            box-shadow: -5.985px -0.419px 30px 0 rgba(0, 0, 0, 0.08), inset -2px 0 10px 0 rgba(0, 0, 0, 0.04);
            top: -80px;
            color: $black;
            i {
                margin-left: 5px;
            }
        }
    }
    .colors {
        a {
            width: 32px;
            height: 32px;
            margin: 5px;
            float: left;
            transition: all ease-in 0.3s;
        }
    }
    .skin {
        a {
            display: block;
            width: 70px;
            height: 30px;
            margin-left: 0;
            margin-top: 0;
            margin-right: 5px;
            float: left;
            text-align: center;
            line-height: 30px;
            border: 2px transparent solid;
            &.actt {
                border: 2px white solid;
            }
        }
    }
}

.dark-light {
    position: fixed;
    right: 0;
    width: 40px;
    height: 38px;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-decoration: none;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    top: calc(50% + 34px);
    cursor: pointer;
}

.setting-sidebar {
    z-index: 9;
    h5 {
        color: #212529;
        text-transform: capitalize;
        line-height: 1.2;
        font-weight: 600;
        margin-bottom: 0;
        font-size: 14px;
        text-align: left;
    }
    i {
        animation: ring-rotate 3s infinite linear;
        font-size: 20px;
        color: #3c3c3c;
        margin-left: 10px;
    }
    &.open-icon {
        right: 351px;
        transition: all 0.5s ease;
    }
}

.setting_box_body {
    position: relative;
    background-color: white;
    z-index: 9;
    height: 100vh;
}

.scroll-setting-box {
    ::-webkit-scrollbar {
        width: 4px;
        height: 7px;
    }
     ::-webkit-scrollbar-track {
        background: $round-border;
    }
     ::-webkit-scrollbar-thumb {
        background: $grey5;
        border-radius: 5px;
    }
     ::-webkit-scrollbar-thumb:hover {
        background: $grey2;
    }
}

.setting-box {
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    width: 660px;
    right: -660px;
    background-color: white;
    top: 0;
    z-index: 999;
    box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
    transition: all 0.5s ease;
    &.open-setting {
        right: 0;
        transition: all 0.5s ease;
        .overlay {
            visibility: visible;
            transition: all 0.5s ease;
        }
    }
    .sidebar-back {
        padding: 20px;
        font-size: 18px;
        color: #222222;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        background-color: rgba(#f3f3f3, 0.80);
    }
    .setting-title {
        border-bottom: 1px solid #efefef;
        margin-bottom: 0;
        text-align: center;
        padding: 30px 20px;
        h3 {
            line-height: 31px;
            margin-top: 10px;
            text-transform: capitalize;
            font-size: 20px;
            margin-bottom: 0;
            color: #a3a3a3;
            span {
                font-family: 'Yellowtail', cursive;
                color: #f54c3b;
                text-transform: uppercase;
            }
        }
        h4 {
            position: relative;
            margin-bottom: 0;
            cursor: pointer;
        }
        &.active {
            .according-menu {
                font: normal normal normal 14px/1 FontAwesome;
                &:before {
                    content: "\f068";
                    position: absolute;
                    right: 2px;
                    top: 2px;
                }
            }
        }
        .according-menu {
            font: normal normal normal 14px/1 FontAwesome;
            &:before {
                content: "\f067";
                position: absolute;
                right: 2px;
                top: 2px;
            }
        }
    }
    .setting-contant {
        padding: 20px;
        border-bottom: 1px solid #efefef;
        .setting_buttons {
            .setting_btn {
                background-color: var(--theme-deafult);
                color: white;
                width: 100%;
            }
            li {
                &:first-child {
                    width: 49%;
                }
                &.active {
                    box-shadow: 0px 0px 5px 0px rgb(146, 146, 146);
                }
                &:last-child {
                    margin-left: 10px;
                    width: 46%;
                }
            }
        }
        .color-box {
            li {
                display: flex;
                align-items: center;
                span {
                    text-transform: capitalize;
                    margin-bottom: 2px;
                    font-size: 15px;
                }
                input {
                    border: none;
                    box-shadow: none;
                    width: 34px;
                    height: 34px;
                    background-color: transparent;
                    margin-right: 5px;
                    &:focus {
                        outline: none;
                    }
                }
                +li {
                    margin-top: 5px;
                }
            }
        }
    }
    .buy_btn {
        padding: 20px;
        a {
            background-color: #f3f3f3;
            color: #3c3c3c;
            padding: 10px;
            transition: all 0.5s ease;
            i {
                margin-right: 5px;
                font-size: 18px;
            }
            img {
                filter: grayscale(100);
            }
        }
    }
    .overlay {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
    }
}

.theme-settings {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    ul {
        li {
            width: 40px;
            height: 38px;
            display: flex;
            z-index: 1;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            text-decoration: none;
            border-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            background-color: #f7f7f7;
            box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
            margin-bottom: 15px;
            margin-left: auto;
            cursor: pointer;
            font-weight: 600;
            &:last-child {
                margin-bottom: 0;
            }
            &.demo-li {
                width: 50px;
                height: 40px;          
            }
            &.input-picker {
                background: none;
                overflow: hidden;
                input {
                    height: 100%;
                    opacity: 1;
                    padding: 0;
                    border: none;
                }
                input::-webkit-color-swatch {
                    border: none;
                }
            }
        }
    }
}

.demo-section {
    .title-text {
        h3 {
            color: var(--theme-deafult);
            margin-bottom: 24px;
            display: inline-block;
            padding-bottom: 5px;
            font-size: 40px;
            position: relative;
            i {
                font-size: 22px;
                position: absolute;
                top: 0;
                right: -20px;
            }
        }
    }
    .demo-effects {
        margin-bottom: 30px;
        h4 {
            text-transform: capitalize;
        }
        .set-position {
            display: block;
        }
        >div {
            text-decoration: none;
            color: black;
            .layout-container {
                height: 130px;
                width: 100%;
                display: inline-flex;
                background-size: cover;
                transition: all 2s ease-out 0s;
                box-shadow: 1px 6px 19px 2px rgba(0,0,0,.1);
                // @for $i from 1 through 70 {
                //     &.demo#{$i} {
                //         background-image: url("../images/landing-page/demo/#{$i}.jpg");
                //     }
                // }
            }
            .demo-text {
                h4 {
                    font-size: 16px;
                    margin-top: 10px;
                    margin-bottom: 0;
                    color: #000000;
                    span {
                        font-weight: 500;
                    }
                }
                .demo-btn {
                    margin-top: 10px;
                    .btn {
                        border: 1px solid #ddd;
                        background-color: white;
                        width: 90px;
                        color: var(--theme-deafult);
                        padding: 5px 10px;
                        border-radius: 30px;
                        &:hover {
                            background-color: var(--theme-deafult);
                            color: white;
                        }
                    }
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &.effect-2 {
            .layout-container {
                background-size: cover;
                background-repeat: no-repeat;
            }
            &:hover {
                .layout-container {
                    background-position: top !important;
                }
            }
        }
        &.effect-3 {
            .layout-container {
                background-size: cover;
                background-repeat: no-repeat;
            }
            &:hover {
                .layout-container {
                    background-position: top !important;
                }
            }
        }
    }
}

@keyframes ring-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.sec-position {
    position: relative;
    top: -80px;
}

.dark-demo {
    .dark-light-btn {
        display: none;
    }
}

.tooltip-sec {
    li {
        position: relative;
        .tooltip-cls {
            position: absolute;
            right: 60px;
            top: 0;
            background-color: white;
            border-radius: 4px;
            padding: 0.5rem 1rem;
            text-transform: capitalize;
            color: #223645;
            box-shadow: 0 0 16px -4px rgba(0, 20, 40, 0.2), 0 0 80px -10px rgba(0, 20, 40, 0.3);
            transform: scale(0);
            transition: all 0.5s ease;
            z-index: 1;
            &:after {
                position: absolute;
                content: "";
                right: -8px;
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-left: 8px solid #edf7fb;
                border-bottom: 7px solid transparent;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &:hover {
            .tooltip-cls {
                transform: scale(1);
                transition: all 0.5s ease;
            }
        }
    }
}